<template>
    <div>
        <div v-if="isEligible">
            <div
                class="landing-page-container">
                <icon
                    :name="platformIcon" />
                <v-text-field
                    v-model="url"
                    class="styled-field ml-2"
                    label="LANDING PAGE URL"
                    :disabled="appliedToAllUrl && adIndex !== 0"
                    :rules="[...requiredRules, ...urlRules, ...httpsRules]" />
            </div>
            <div
                v-if="totalAds > 1 && adIndex == 0"
                class="apply"
                :class="{active: updateAllPlatformUrls}"
                @click="toggleUpdateAllPlatformUrls">
                <div class="apply__circle" />
                <div>Apply this URL to all ads</div>
            </div>
            <div
                v-else
                class="apply__placeholder" />
        </div>
        <div
            v-else
            class="main-placeholder" />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { urlRules, httpsRules, requiredRules } from '@/helpers/validationRules';
import { mapState, mapGetters } from 'vuex';
export default {
    name: 'LandingPageUrl',
    components: {
        Icon,
    },
    props: {
        platform: {
            type: String,
            required: true
        },
        adIndex: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            requiredRules,
            urlRules,
            httpsRules,
            updateAllPlatformUrls: false,
        };
    },
    computed: {
        ...mapGetters('adDeployment', [
            'availablePlatforms',
        ]),
        ...mapState({
            ads: (state) => state.adDeployment.ads
        }),
        appliedToAllUrl() {
            return this.$store.state.adDeployment.url_config.platform_specific[this.platform];
        },
        adData() {
            return this.ads[this.adIndex];
        },
        totalAds() {
            return this.ads.length;
        },
        platformSpecificLink() {
            return this.adData?.configuration?.platform_specific[this.platform]?.cta_link;
        },
        allPlatformsLink() {
            return this.adData?.configuration?.all_platforms.cta_link;
        },
        url: {
            get() {
                return this.platformSpecificLink ?? this.allPlatformsLink;
            },
            set(newUrl) {
                if (this.updateAllPlatformUrls  && this.adIndex == 0) {
                    this.setUrlForAllAds(newUrl);
                } else { // only update the url for the specific ad and platform
                    this.$store.commit('adDeployment/updateLandingPageUrlForPlatform', {
                        platform: this.platform,
                        value: newUrl,
                        adIndex: this.adIndex
                    });
                }
            }
        },
        platformIcon() {
            return this.platform == 'facebook' ? 'meta' : this.platform;
        },
        isEligible() {
            let currentPlatformAds = [];
            this.availablePlatforms.forEach(platform => {
                if (this.platform == platform.name) {
                    currentPlatformAds = platform.ads;
                }
            });
            const eligibleAd = currentPlatformAds.find(ad => ad.id == this.adData.id);
            if (eligibleAd) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted() {
        if (this.isEligible && this.appliedToAllUrl && this.adIndex === 0) {
            this.setUrlForAllAds(this.url);
            this.updateAllPlatformUrls = true;
        }
    },
    methods: {
        setUrlForAllAds(url) {
            // loop over all the ads and set the url for all the ads and only the specific platform
            this.ads.forEach((ad, index) => {
                this.$store.commit('adDeployment/updateLandingPageUrlForPlatform', {
                    platform: this.platform,
                    value: url,
                    adIndex: index
                });
            });
        },
        toggleUpdateAllPlatformUrls() {
            this.updateAllPlatformUrls = !this.updateAllPlatformUrls;
            this.$store.commit('adDeployment/setApplyToAllUrl', {
                platform: this.platform,
                value: this.updateAllPlatformUrls,
            });
            if (this.updateAllPlatformUrls  && this.adIndex == 0) {
                this.setUrlForAllAds(this.url);
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.landing-page-container {
    display: flex;
    align-items: center;
    margin-top: 40px;
}
.main-placeholder{
    height: 105px;
}
.apply{
    margin-top: 10px;
    margin-left:35px;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
    &__circle{
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #03A2EA;
        position: relative;
    }
    &__placeholder{
        height: 31px;
    }
    &.active{
        .apply__circle{
            &:after{
                content: "";
                position: absolute;
                width: 12px;
                border-radius: 50%;
                height: 12px;
                left:2px;
                top:2px;
                background: #03A2EA;
            }
        }
    }
}
</style>
