<template>
    <styled-button
        class="edit-button"
        text
        @click.prevent="handleEdit">
        edit
    </styled-button>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';

export default {
    components: {
        StyledButton
    },
    props: {
        step: {
            type: String,
            required: true
        }
    },
    methods: {
        handleEdit() {
            this.$store.dispatch(
                'adDeployment/goToStepByName',
                this.step
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.edit-button {
    margin-bottom: 10px;
    margin-left: 15px;
}
</style>