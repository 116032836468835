<template>
    <div
        class="deployment"
        :class="{
            'invalid': isInvalid
        }">
        <div class="deployment__top">
            <div class="deployment__step">
                {{ index + 1 }}
            </div>
            <div class="deployment__text">
                <div class="deployment__title mb-2">
                    <span class="mr-3">{{ title }}</span>
                    <action-button
                        v-if="!hideArticles"
                        icon="book"
                        :href="helpLink"
                        target="_blank"
                        color="#8F9EA6"
                        size="20" />
                    <a
                        v-if="active && !hideArticles"
                        :href="helpLink"
                        target="_blank"
                        class="deployment__link">Read more</a>
                </div>
                <div
                    v-if="isInvalid"
                    class="deployment__error">
                    <div>
                        There is an error with this step. Please click edit
                        and resolve the errors before continuing.
                    </div>
                    <styled-button
                        class="error-button"
                        small
                        @click="handleEdit">
                        EDIT
                    </styled-button>
                </div>
                <div
                    v-else-if="active"
                    class="deployment__desc">
                    {{ description }}
                </div>
            </div>
        </div>
        <div
            v-show="!hideContent"
            class="deployment__content"
            :class="{
                'no-indent': noIndent
            }">
            <slot />
        </div>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import ActionButton from '@/components/globals/ActionButton';
import { mapState } from 'vuex';

export default {
    components: {
        StyledButton,
        ActionButton
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        active: {
            type: Boolean,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        helpLink: {
            type: String,
            default: ''
        },
        noIndent: {
            type: Boolean,
            default: false
        },
        valid: {
            type: Boolean,
            default: true
        },
        step: {
            type: String,
            required: true
        },
        hideArticles: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            latestStep: (state) => state.adDeployment.latest_step
        }),
        isInvalid() {
            return (
                !this.valid &&
                !this.active &&
                this.latestStep > this.index
            );
        },
        hideContent() {
            return !this.active && this.isInvalid;
        }
    },
    methods: {
        handleEdit() {
            this.$store.dispatch(
                'adDeployment/goToStepByName',
                this.step
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.deployment {
    &.invalid {
        .deployment__title {
            color: $error-500;
        }
        .deployment__step {
            background: $error-500;
        }
    }
    &__top{
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        padding-bottom: 15px;
        margin-top: 45px;
    }
    &__step{
        font-size: 18px;
        font-weight: 700;
        color: white;
        width: 30px;
        height: 30px;
        background: #03A2EA;
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
    }
    &__text{
        max-width: calc(100% - 65px);
    }
    &__title{
        color: #03A2EA;
        font-size: 18px;
    }
    &__desc{
        color: #757575;
    }
    &__link{
        color: #03A2EA;
        font-size: 14px;
        margin-left: 15px;
    }
    &__content:not(.no-indent) {
        max-width: 800px;
        margin: 0 auto;
        padding-left: 60px;
    }
    &__error {
        display: flex;
        align-items: center;
        max-width: 550px;
        margin-top: 15px;
        border: 1px solid $error-500;
        padding: 20px 25px;
        border-radius: 5px;
        background: $white;
    }
}
.error-button {
    border-color: $error-500;
    background-color: $error-500;
    margin-left: 20px;
    &:hover {
        background-color: darken($error-500, 20%);
    }
}
</style>
