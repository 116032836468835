export default {
    active: {
        type: Boolean,
        required: true
    },
    valid: {
        type: Boolean,
        required: true
    },
    index: {
        type: Number,
        required: true
    },
    name: {
        type: String,
        required: true
    }
};