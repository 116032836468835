<template>
    <div class="step-sad-wrapper">
        <div class="complete-wrapper">
            <div class="ads-wrapper">
                <div
                    v-for="ad in ads"
                    :key="ad.id"
                    class="ad">
                    <span class="ad__title">SINGLE AD</span>
                    <span class="ad__name">{{ ad.display_name }}</span>
                </div>
            </div>
            <edit-button step="ad_configuration" />
        </div>
    </div>
</template>

<script>
import EditButton from '../../components/EditButton';
import { mapState } from 'vuex';

export default {
    components: {
        EditButton
    },
    computed: {
        ...mapState({
            ads: (state) => state.adDeployment.ads
        })
    },
    methods: {
        handleEdit() {
            this.$store.dispatch(
                'adDeployment/goToStepByName',
                'ad_configuration'
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.complete-wrapper {
    display: flex;
    align-items: center;
}
.ads-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.ad {
    display: inline-block;
    background: white;
    padding: 10px 15px;
    margin: 6px;
    border: 1px solid #DFDFE0;
    white-space: nowrap;
    &__title{
        font-weight: 700;
        margin-right: 15px;
    }
    &__name{
        color: #03A2EA;
        font-style: italic;
    }
}
</style>