<template>
    <div>
        <div class="setup__block">
            <div class="setup__desc">
                {{ assetName }} AD
            </div>
            <div class="setup__media">
                <!-- todo use the media type constants -->
                <img
                    v-if="assetType === IMAGE_ASSET_TYPE && assetUrl"
                    :src="assetUrl"
                    alt="">
                <img
                    v-if="assetType === DESIGN_ASSET_TYPE && assetUrl"
                    :src="assetUrl"
                    alt="">
                <video
                    v-else-if="assetType === VIDEO_ASSET_TYPE && assetUrl"
                    muted
                    loop>
                    <source
                        :src="assetUrl"
                        type="video/mp4">
                </video>
            </div>
            <div class="setup__name mb-3">
                {{ adName }}
            </div>
            <styled-button
                small
                @click.prevent="$emit('edit-ad', adIndex)">
                <span>EDIT</span>
            </styled-button>
            <div
                v-if="!urlCustomization"
                class="setup__field">
                <div class="landing-page-container">
                    <v-text-field
                        v-model="url"
                        class="styled-field ml-2"
                        label="LANDING PAGE URL"
                        :disabled="appliedToAllAds && adIndex !== 0"
                        :rules="[...requiredRules, ...urlRules, ...httpsRules]" />
                </div>
                <div
                    v-if="totalAds > 1 && adIndex == 0"
                    class="apply"
                    :class="{active: appliedToAllAds}"
                    @click="toggleUpdateAllAdsUrls">
                    <div class="apply-circle" />
                    <div>Apply this URL to all ads</div>
                </div>
            </div>
            <div
                v-else
                class="setup__field">
                <landing-page-url
                    v-for="(deploymentPlatform, index) in deploymentPlatforms"
                    :key="index"
                    :ad-index="adIndex"
                    :platform="deploymentPlatform" />
            </div>
        </div>
    </div>
</template>

<script>
import { urlRules, httpsRules, requiredRules } from '@/helpers/validationRules';
import StyledButton from '@/components/globals/StyledButton';
import { mapState } from 'vuex';
import LandingPageUrl from '@/components/ad-deployment/steps/AdConfiguration/LandingPageUrl';
import {
    VIDEO_ASSET_TYPE,
    IMAGE_ASSET_TYPE,
    DESIGN_ASSET_TYPE
 } from '@/components/ad-studio/store/constants';

export default {
    components: {
        LandingPageUrl,
        StyledButton
    },
    props: {
        adIndex: {
            type: Number,
            required: true
        },
        urlCustomization: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            multipleUrls: false,
            requiredRules,
            urlRules,
            httpsRules,
            VIDEO_ASSET_TYPE,
            IMAGE_ASSET_TYPE,
            DESIGN_ASSET_TYPE
        };
    },
    computed: {
        ...mapState({
            ads: (state) => state.adDeployment.ads,
            deploymentPlatforms: (state) => state.adDeployment.platforms
        }),
        adData() {
            return this.ads[this.adIndex];
        },
        totalAds() {
            return this.ads.length;
        },
        assetType() {
            return this.adData?.configuration?.all_platforms?.creatives[0]?.assets[0]?.asset_type?.name;
        },
        assetName() {
            switch (this.assetType) {
                case IMAGE_ASSET_TYPE:
                    return 'IMAGE';
                case VIDEO_ASSET_TYPE:
                    return 'VIDEO';
                case DESIGN_ASSET_TYPE:
                    return 'DESIGN';
                default:
                    return 'IMAGE';
            }
        },
        assetUrl() {
            return this.adData?.configuration?.all_platforms.creatives[0].assets[0].url;
        },
        adName() {
            return this.adData?.display_name;
        },
        appliedToAllAds() {
            return this.$store.state.adDeployment.url_config.applied_to_all_ads;
        },
        allPlatformsLink() {
            return this.adData?.configuration?.all_platforms.cta_link;
        },
        url: {
            get() {
                return this.allPlatformsLink;
            },
            set(newUrl) {
                if (this.appliedToAllAds) {
                    this.ads.forEach((ad, index) => {
                        this.updateUrlsForCurrentAd(newUrl, index);
                    });
                } else {
                    this.updateUrlsForCurrentAd(newUrl, this.adIndex);
                }
            }
        },
    },
    watch: {
        urlCustomization(value) {
            if (!value) {
                this.updateUrlsForCurrentAd(this.url, this.adIndex);
            }
        }
    },
    mounted() {
        if (this.appliedToAllAds && this.adIndex === 0 && !this.urlCustomization) {
            this.ads.forEach((ad, index) => {
                this.updateUrlsForCurrentAd(this.url, index);
            });
        }
    },
    methods: {
        updateUrlsForCurrentAd(newUrl, index) {
            this.deploymentPlatforms.forEach(platform => {
                this.$store.commit('adDeployment/updateLandingPageUrlForAllPlatforms', {
                    value: newUrl,
                    adIndex: index
                });
                this.$store.commit('adDeployment/updateLandingPageUrlForPlatform', {
                    platform,
                    value: newUrl,
                    adIndex: index
                });
            });
        },
        toggleUpdateAllAdsUrls() {
            this.$store.commit('adDeployment/toggleAppliedToAllAds');
            if (this.appliedToAllAds) {
                this.ads.forEach((ad, index) => {
                    this.updateUrlsForCurrentAd(this.url, index);
                });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.setup__media {
    height: 150px;
    img,video{
        max-width: 150px;
        max-height: 150px;
    }
}
.setup__block{
    min-width: 330px;
    text-align: center;
}
.setup__desc{
    font-size: 14px;
    color: #03A2EA;
    margin-bottom: 20px;
}
.setup__name{
    margin-top: 15px;
    font-size: 12px;
}
.want-more{
    margin-top: 30px;
    margin-bottom: 15px;
    color: #767676;
}
.advanced{
    color: #03A2EA;
    text-decoration: underline;
    cursor: pointer;
}
.setup__error{
    text-align: left;
    color: red;
    font-size: 12px;
}
.underlined{
    text-decoration: underline;
    cursor: pointer;
}

.finished-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.finished{
    max-width: calc(100% - 100px);
    display: inline-block;
    background: white;
    padding: 10px 45px 10px 15px;
    border: 1px solid #DFDFE0;
    &__title{
        font-weight: 700;
        margin-right: 15px;
    }
    &__name{
        color: #03A2EA;
        font-style: italic;
    }
}
.landing-page-container {
    margin-top: 40px;
}
.apply{
    margin-top: 10px;
    margin-left:15px;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.apply.active .apply-circle {
    &:after{
        content: "";
        position: absolute;
        width: 12px;
        border-radius: 50%;
        height: 12px;
        left: 2px;
        top: 2px;
        background: #03A2EA;
    }
}
.apply-circle{
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #03A2EA;
    position: relative;
}
</style>
