<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        title="Customize your ads"
        :help-link="helpLink"
        :description="description"
        no-indent>
        <complete v-if="!active" />
        <template v-else>
            <div class="setup-wrapper">
                <div class="setup__blur setup__blur-left" />
                <div class="setup__blur setup__blur-right" />
                <div class="step-sad-wrapper">
                    <div class="setup__title">
                        Simple setup
                    </div>
                    <v-form
                        ref="form"
                        v-model="urlsValid">
                        <div class="play-carousel">
                            <carousel
                                :per-page="1"
                                :pagination-size="1"
                                :mouse-drag="true"
                                :navigation-enabled="true"
                                :pagination-enabled="false"
                                navigation-next-label=""
                                navigation-prev-label="">
                                <slide
                                    v-for="(ad, index) in ads"
                                    :key="index"
                                    class="slide">
                                    <single-ad
                                        :ad-data="ad"
                                        :ad-index="index"
                                        :total-ads="ads.length"
                                        :active-url-channels="activeUrlChannels"
                                        :url-customization="urlCustomization"
                                        @edit-ad="editSingleAd" />
                                    <div
                                        v-if="index != (ads.length - 1)"
                                        class="as-divider ml-5 mr-5" />
                                </slide>
                            </carousel>
                        </div>
                    </v-form>
                </div>
            </div>
            <div class="step-sad-wrapper">
                <div class="url-customization">
                    <v-switch
                        v-model="urlCustomization"
                        class="switcher"
                        color="primary" />
                    <div class="ml-2">
                        Customize Landing Page URLs per channel
                    </div>
                </div>
                <div class="want-more">
                    Want more ad control? <span
                        class="advanced"
                        @click="showAdStudio">Advanced Edit</span>
                    <icon-with-tooltip
                        class="single-icon"
                        has-tooltip
                        icon="question-circle"
                        size="18"
                        tooltip="When using Advanced Edit, any changes that are made to your Ad(s) will be automatically updated to the Ads(s) that you are deploying. These updates will not update the base Ad(s), they will just be modified for this deployment." />
                </div>
            </div>
            <div class="button-sad-holder button-container">
                <styled-button
                    small
                    :disabled="(!valid || !urlsValid)"
                    class=""
                    @click="goToNextStep">
                    <span>Continue</span>
                </styled-button>
            </div>
            <!-- AD STUDIO -->
            <ad-studio
                v-if="adStudio"
                :open-from-editing="true"
                @close="closeAdStudio" />
        </template>
    </step-wrapper>
</template>

<script>
import StepWrapper from '../../components/StepWrapper';
import StyledButton from '@/components/globals/StyledButton';
import AdStudio from '@/components/ad-studio/AdStudio';
import IconWithTooltip from '@/components/ad-studio/editing/preview-step/IconWithTooltip';
import SingleAd from './SingleAd';
import Complete from './Complete';
import stepProps from '../stepProps';
import { mapActions, mapState } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'AdConfiguration',
    components: {
        StyledButton,
        AdStudio,
        IconWithTooltip,
        SingleAd,
        Complete,
        StepWrapper,
        Carousel,
        Slide
    },
    props: stepProps,
    data() {
        return {
            helpLink: 'https://support.buyerbridge.com/knowledge/how-to-ad-launcher#preset-customize-ad',
            description: 'Review the ads within this deployment below and ensure all landing pages are correct for each channel that you are deploying to.',
            buttonDisabled: true,
            stepCompleted: false,
            adName: '',
            mediaUrl: '',
            url: '',
            urlWasSet: false,
            multipleUrls: false,
            urlData: null,
            activeUrlChannels: [],
            adStudio: false,
            urlsValid: false
        };
    },
    mounted() {
        this.setSiteBaseUrl();
    },
    computed: {
        ...mapState({
            deploymentPlatforms: (state) => state.adDeployment.platforms,
            currentDealer: (state) => state.dealer.currentDealer,
            ads: (state) => state.adDeployment.ads
        }),
        urlCustomization: {
            get() {
                return this.$store.state.adDeployment.url_config.customize_per_channel;
            },
            set(value) {
                this.$store.commit('adDeployment/setUrlCustomization', value);
            }
        },
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
        setSiteBaseUrl() {
            // set site_base_url if we are deploying previously created ad
            if (this.$route.query.from_created) {
                this.ads.forEach(ad => {
                    ad.configuration.all_platforms.cta_link = this.currentDealer.site_base_url;
                    this.deploymentPlatforms.forEach(platform => {
                        ad.configuration.platform_specific[platform].cta_link = this.currentDealer.site_base_url;
                    });
                });
            }
        },
        updateAd({ adData, id }) {
            let newAds = this.ads.map(ad => ad.id === id ? adData : ad );

            this.$store.commit('adDeployment/setAds', newAds);
        },
        showAdStudio() {
            // Set the ads in the ad deployment store and open the modal
            this.$store.commit('adStudio/setAllAds', {
                ads: this.ads
            });
            this.adStudio = true;
        },
        editSingleAd(index) {
            this.$store.commit('adStudio/setAllAds', {
                ads: [this.ads[index]]
            });
            this.adStudio = true;
        },
        closeAdStudio() {
            this.adStudio = false;
            this.$emit('refresh');
        }
    }
};
</script>
<style lang="scss" scoped>
.setup__container{
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
}
.setup-wrapper{
    padding: 15px;
    background: #EDEEF4;
    position: relative;
}
.setup__blur{
    position:absolute;
    top: 0;
    bottom: 0;
    width: 300px;
    z-index: 1;
    &-left{
        left:0;
        background: linear-gradient(to right, #EDEEF4 0%, #EDEEF4 25%, transparent 100%);
    }
    &-right{
        right:0;
        background: linear-gradient(to right, transparent 0%, #EDEEF4 75%, #EDEEF4 100%);
    }
}
.setup__title{
    font-size: 16px;
    color: #03A2EA;
    margin-bottom: 30px;
}
.url-customization {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}
.want-more{
    margin-top: 5px;
    margin-bottom: 15px;
    color: #767676;
}
.advanced{
    color: #03A2EA;
    text-decoration: underline;
    cursor: pointer;
}
.button-container {
    padding-left: 60px;
    max-width: 800px;
    margin: 0 auto;
}
.slide {
    display: flex;
    flex-direction: row;
}
.as-divider {
    width: 1px;
    height: 100%;
    background-color: $gray;
}
</style>
<style lang='scss'>
.play-carousel {
    .VueCarousel {
        margin: 0 20rem 0 4rem;
        position: initial;
        .VueCarousel-wrapper {
            overflow: visible;
        }
        .VueCarousel-inner {
            margin-bottom: 2rem;
            visibility: visible !important;
        }
        .VueCarousel-navigation {
            position: absolute;
            z-index: 2;
            left:-45px;
            right:-45px;
            top:50%;
            button {
                position: absolute;
                transform: scale(1.5);
                bottom:0;
                color: #2693c9!important;
                height:35px;
                transition: opacity 0.3s ease-in-out;
            }

            .VueCarousel-navigation--disabled {
                opacity: 0;
                color: #000!important;
                filter: invert(0%) sepia(94%) saturate(22%) hue-rotate(49deg) brightness(102%) contrast(106%);
            }

            .VueCarousel-navigation-prev {
                left:70px!important;
            }

            .VueCarousel-navigation-prev:before {
                content: url('/img/chevron-left.svg');
                filter: invert(58%) sepia(79%) saturate(653%) hue-rotate(175deg) brightness(107%) contrast(94%);
            }

            .VueCarousel-navigation-next:after {
                content: url('/img/chevron-right.svg');
                filter: invert(58%) sepia(79%) saturate(653%) hue-rotate(175deg) brightness(107%) contrast(94%);
            }

            .VueCarousel-navigation-next {
                right: 70px!important;
            }
        }

        .VueCarousel-dot--active {
            background-color: #2693c9!important;
        }
    }
}
</style>
